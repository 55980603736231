var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page404" }, [
    _vm._m(0),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "left-section" }, [
        _c("div", { staticClass: "inner-content" }, [
          _c("h1", { staticClass: "heading" }, [_vm._v("404")]),
          _c("p", { staticClass: "subheading" }, [
            _vm._v(_vm._s(_vm.$language("존재하지 않는 페이지입니다."))),
          ]),
          _c(
            "p",
            { staticClass: "subheading2" },
            [
              _c(
                "router-link",
                { staticClass: "bullshit__return-home", attrs: { to: "/" } },
                [_vm._v(_vm._s(_vm.$language("메인화면으로 이동")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "right-section" }, [
        _c(
          "svg",
          {
            staticClass: "svgimg",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "51.5 -15.288 385 505.565",
            },
          },
          [
            _c("g", { staticClass: "bench-legs" }, [
              _c("path", {
                attrs: {
                  d: "M202.778,391.666h11.111v98.611h-11.111V391.666z M370.833,390.277h11.111v100h-11.111V390.277z M183.333,456.944h11.111\r\n          v33.333h-11.111V456.944z M393.056,456.944h11.111v33.333h-11.111V456.944z",
                },
              }),
            ]),
            _c("g", { staticClass: "top-bench" }, [
              _c("path", {
                attrs: {
                  d: "M396.527,397.917c0,1.534-1.243,2.777-2.777,2.777H190.972c-1.534,0-2.778-1.243-2.778-2.777v-8.333\r\n          c0-1.535,1.244-2.778,2.778-2.778H393.75c1.534,0,2.777,1.243,2.777,2.778V397.917z M400.694,414.583\r\n          c0,1.534-1.243,2.778-2.777,2.778H188.194c-1.534,0-2.778-1.244-2.778-2.778v-8.333c0-1.534,1.244-2.777,2.778-2.777h209.723\r\n          c1.534,0,2.777,1.243,2.777,2.777V414.583z M403.473,431.25c0,1.534-1.244,2.777-2.778,2.777H184.028\r\n          c-1.534,0-2.778-1.243-2.778-2.777v-8.333c0-1.534,1.244-2.778,2.778-2.778h216.667c1.534,0,2.778,1.244,2.778,2.778V431.25z",
                },
              }),
            ]),
            _c("g", { staticClass: "bottom-bench" }, [
              _c("path", {
                attrs: {
                  d: "M417.361,459.027c0,0.769-1.244,1.39-2.778,1.39H170.139c-1.533,0-2.777-0.621-2.777-1.39v-4.86\r\n          c0-0.769,1.244-0.694,2.777-0.694h244.444c1.534,0,2.778-0.074,2.778,0.694V459.027z",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M185.417,443.75H400c0,0,18.143,9.721,17.361,10.417l-250-0.696C167.303,451.65,185.417,443.75,185.417,443.75z",
                },
              }),
            ]),
            _c(
              "g",
              { attrs: { id: "lamp" } },
              [
                _c("path", {
                  staticClass: "lamp-details",
                  attrs: {
                    d: "M125.694,421.997c0,1.257-0.73,3.697-1.633,3.697H113.44c-0.903,0-1.633-2.44-1.633-3.697V84.917\r\n          c0-1.257,0.73-2.278,1.633-2.278h10.621c0.903,0,1.633,1.02,1.633,2.278V421.997z",
                  },
                }),
                _c("path", {
                  staticClass: "lamp-accent",
                  attrs: {
                    d: "M128.472,93.75c0,1.534-1.244,2.778-2.778,2.778h-13.889c-1.534,0-2.778-1.244-2.778-2.778V79.861\r\n          c0-1.534,1.244-2.778,2.778-2.778h13.889c1.534,0,2.778,1.244,2.778,2.778V93.75z",
                  },
                }),
                _c("circle", {
                  staticClass: "lamp-light",
                  attrs: { cx: "119.676", cy: "44.22", r: "40.51" },
                }),
                _c("path", {
                  staticClass: "lamp-details",
                  attrs: {
                    d: "M149.306,71.528c0,3.242-13.37,13.889-29.861,13.889S89.583,75.232,89.583,71.528c0-4.166,13.369-13.889,29.861-13.889\r\n          S149.306,67.362,149.306,71.528z",
                  },
                }),
                _c(
                  "radialGradient",
                  {
                    staticClass: "light-gradient",
                    attrs: {
                      id: "SVGID_1_",
                      cx: "119.676",
                      cy: "44.22",
                      r: "65",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", {
                      staticStyle: {
                        "stop-color": "#FFFFFF",
                        "stop-opacity": "1",
                      },
                      attrs: { offset: "0%" },
                    }),
                    _c(
                      "stop",
                      {
                        staticStyle: {
                          "stop-color": "#EDEDED",
                          "stop-opacity": "0.5",
                        },
                        attrs: { offset: "50%" },
                      },
                      [
                        _c("animate", {
                          attrs: {
                            attributeName: "stop-opacity",
                            values: "0.0; 0.5; 0.0",
                            dur: "5000ms",
                            repeatCount: "indefinite",
                          },
                        }),
                      ]
                    ),
                    _c("stop", {
                      staticStyle: {
                        "stop-color": "#EDEDED",
                        "stop-opacity": "0",
                      },
                      attrs: { offset: "100%" },
                    }),
                  ],
                  1
                ),
                _c("circle", {
                  staticClass: "lamp-light__glow",
                  attrs: {
                    fill: "url(#SVGID_1_)",
                    cx: "119.676",
                    cy: "44.22",
                    r: "65",
                  },
                }),
                _c("path", {
                  staticClass: "lamp-bottom",
                  attrs: {
                    d: "M135.417,487.781c0,1.378-1.244,2.496-2.778,2.496H106.25c-1.534,0-2.778-1.118-2.778-2.496v-74.869\r\n          c0-1.378,1.244-2.495,2.778-2.495h26.389c1.534,0,2.778,1.117,2.778,2.495V487.781z",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background" }, [
      _c("div", { staticClass: "ground" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }